import React, { Component } from "react";
import "./Optin.css";

class Optin extends Component {
    redirect() {
        window.location.href = "https://tokens.bamonyo.com";
    }

    render() {
        return (
            <div className="optin">
                <p>Access to your coins :</p>
                <button onClick={() => this.redirect()}>Go coins</button>
            </div>
        );
    }
}

export default Optin;
